import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { GuarantorFormPjViews } from 'views/guarantor-form-pj';

export const GuarantorFormPjLayout = () => {
  return (
    <Layout>
      <Layout.Content>
        <div className='guarantor-form-container'>
          <Switch>
            <Route path='' component={GuarantorFormPjViews} />
          </Switch>
        </div>
      </Layout.Content>
    </Layout>
  );
};
