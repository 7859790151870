import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SimulationViews from 'views/simulation-views';
import HeaderNav from 'components/layout-components/HeaderNav';
import utils from 'utils';
import { Grid, Layout } from 'antd';

const { useBreakpoint } = Grid;
const { Content } = Layout;

export const SimulationLayout = () => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Content>
        <div className='simulations-container'>
          <Switch>
            <Route path='' component={SimulationViews} />
          </Switch>
        </div>
      </Content>
    </Layout>
  );
};

export default SimulationLayout;
