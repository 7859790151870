import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { SIMULATION_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route
          path={`${SIMULATION_PREFIX_PATH}`}
          component={lazy(() => import(`./authentication/simulation`))}
        />

        {/* <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-2`))} /> */}
        <Redirect
          from={`${SIMULATION_PREFIX_PATH}`}
          to={`${SIMULATION_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
