import * as Services from 'services';
import Utils from "utils";

export const uploadImage = async (content = []) => {
		let result;
		result = await Services.uploadService.upload(content);
		if (!result.error) {
				console.log('result', result);
				Utils.feedback('success', 'SUCESSO', 'Registro salvo com sucesso');
				return result.data.url;
		} else {
				Utils.feedback('error', 'Alerta', 'Houve uma falha, tente novamente!');
				return 'false';
		}
}
