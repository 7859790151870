import * as Actions from "../constants/Theme";
import { THEME_CONFIG } from "configs/AppConfig";

const initTheme = { ...THEME_CONFIG };

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case Actions.TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case Actions.SIDE_NAV_STYLE_CHANGE:
      return {
        ...state,
        sideNavTheme: action.sideNavTheme,
      };
    case Actions.CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case Actions.NAV_TYPE_CHANGE:
      return {
        ...state,
        navType: action.navType,
      };
    case Actions.TOP_NAV_COLOR_CHANGE:
      return {
        ...state,
        topNavColor: action.topNavColor,
      };
    case Actions.HEADER_NAV_COLOR_CHANGE:
      return {
        ...state,
        headerNavColor: action.headerNavColor,
      };
    case Actions.TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.mobileNav,
      };
    default:
      return state;
  }
};

export default theme;
