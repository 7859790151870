import * as Actions from "../constants/System.constants";

const initState = {
  loading: false,
  loadSpin: true,
  message: "",
  showMessage: false,
  files: [],
  endpoint: "",
  category: 0,
  banner: "",
  tipo: 0,
};

const system = (state = initState, action) => {
  switch (action.type) {
    case Actions.SET_BANNER: {
      return {
        ...state,
        banner: action.payload.value,
        tipo: action.payload.tipo,
      };
    }
    case Actions.SYSTEM_SHOW_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.SYSTEM_HIDE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case Actions.SET_PAGE: {
      console.log(action.payload);
      return {
        ...state,
        endpoint: action.payload.endpoint,
        category: action.payload.category,
      };
    }
    case Actions.SYSTEM_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: action.show,
        loading: action.show ? false : state.loading,
      };
    case Actions.SYSTEM_LOADING:
      return {
        ...state,
        loadSpin: action.value,
      };
    case Actions.LIST_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case Actions.ADD_FILES:
      return {
        ...state,
        files: [...state.files, { ...action.payload }],
      };
    default:
      return state;
  }
};

export default system;
