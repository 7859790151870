import styled from 'styled-components';

const Content = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: block;

  .buttonLogin{
    background: #fbaa23;
    border-color: #fbaa23;
    color: black;
    margin-right: 30px;
  }

`
export default Content;