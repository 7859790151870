import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { GuarantorFormViews } from 'views/guarantor-form';
import { Layout } from 'antd';

export const GuarantorFormLayout = () => {
  return (
    <Layout>
      <Layout.Content>
        <div className='guarantor-form-container'>
          <Switch>
            <Route path='' component={GuarantorFormViews} />
          </Switch>
        </div>
      </Layout.Content>
    </Layout>
  );
};
