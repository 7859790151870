import React, { createContext, useState } from 'react';

const DataUserContext = createContext();

function DataUserProvider({children}){
    const [dataUser, setDataUser] = useState({});
    const [dataProfile, setDataProfile] = useState({
        id: null,
        user_id: null,
        birthday: null,
        genre: null,
        relation: null,
        phone: null,
        cep: null,
        state: null,
        city: null,
        neighborhood: null,
        addressNumber: null,
        addressComplement: null,
        address: null
    });

    const setProfileData = (data) => {
        setDataProfile({...dataProfile, ...data});
    }

    const setUserData = (data) => {
        setDataUser(data);
    }

    return (
        <DataUserContext.Provider 
            value={{
                dataUser,
                setUserData,
                setProfileData,
                dataProfile,
            }}
        >
            {children}
        </DataUserContext.Provider>
    )
}


export {DataUserProvider, DataUserContext}