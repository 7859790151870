import { api } from '.'
import * as Actions from "../redux/constants/Auth";

const profileService = {}
const config = () => {
	return {
		headers: {'Authorization': `${localStorage.getItem(Actions.AUTH_TOKEN)}`}
	}
}
profileService.getProfileId = async function () {
	let result = null;
	await api.get(`/api/users/me`, config())
			.then(res => {
				result = { ...res.data, error: false, type: res.status}
			})
			.catch(error => {
				console.log('error', error)
				result = {data: null, error: true, type: error}
			})
	return result;
}

profileService.putProfile = async function (data) {
	let result = null;
	await api.put(`/api/users/entrepreneurs`, data, config())
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

profileService.putProfileUser = async function (data) {
	let result = null;
	await api.put(`/api/profiles/me`, data, config())
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

export default profileService
