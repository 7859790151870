import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Projects from 'views/projects';
import { Layout } from 'antd';

const { Content } = Layout;

export const SolicitationLayout = () => {
  return (
    <Layout>
      <Content>
        <div className='solicitation-container'>
          <Switch>
            <Route path='' component={Projects} />
          </Switch>
        </div>
      </Content>
    </Layout>
  );
};

export default SolicitationLayout;
