export const EMPTY_SOLICITATION_DATA = {
  id: null,
  user_id: null,
  typeCredit: null,
  cnpj: null,
  legalName: null,
  birthday: null,
  genre: null,
  relations: null,
  email: null,
  phone: null,
  cep: null,
  address: null,
  addressNumber: null,
  addressComplement: null,
  neighborhood: null,
  financingValue: null,
  city: null,
  state: null,
  companyType: null,
  nationality: null,
  instagram: null,
  facebook: null,
  site: null,
  profileAccountBank: null,
  cpfCnpj: null,
  nameCreditCard: null,
  bankName: null,
  bankAgency: null,
  bankNumber: null,
  bankDigit: null,
  typeAccount: null,
  keyPix: null,
  certiMEI: null,
  extratoBancario: null,
  fotoFachada: null,
  fotoInterior: null,
  compEndereco: null,
  docFrente: null,
  docVerso: null,
  fotoRgCnh: null,
  docSelfie: null,
  comprResid: null,
  extraBancarioEmpresa: null,
  partners: null,
  lendingPlotsToPay: null,
  branchOfActivity: null,
  lendingValue: null,
  howDidFind: null,
  howDidFindOther: null,
  companyName: null,
  companyCep: null,
  companyAddress: null,
  companyAddressNumber: null,
  companyAddressNeighborhood: null,
  companyCity: null,
  companyState: null,
  companyAddressComplement: null,
  docRNM: null,
  docRefugeProtocol: null,
  documentType: null,
  numDocumentForeign: null,
  cpf: null,
  project: null,
  lendingReason: null,
  status: "PENDING",
  profession: null,
};
