import React from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, LogoutOutlined, HomeOutlined } from "@ant-design/icons";

import * as Actions from "redux/actions/Auth";

import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

import utils from "utils";
import { useHistory } from "react-router-dom";

export const NavProfile = () => {
  let history = useHistory();

  const auth_user = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const signOut = async () => {
    dispatch(Actions.signOut());
    localStorage.clear();
    history.push(`${AUTH_PREFIX_PATH}/login`);
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-1">
            <h4 className="mb-0">{auth_user.user.name}</h4>
            <span className="text-muted font-size-sm" wfd-id="390">
              {auth_user.user.email}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={0}>
            <a href={`${APP_PREFIX_PATH}/home`}>
              <HomeOutlined className="mr-3" />
              <span className="font-weight-normal">Home</span>
            </a>
          </Menu.Item>
          <Menu.Item key={0}>
            <a href={`${APP_PREFIX_PATH}/profile`}>
              <EditOutlined className="mr-3" />
              <span className="font-weight-normal">Perfil</span>
            </a>
          </Menu.Item>
          <Menu.Item key={99} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sair</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar
            className="bg-primary"
            style={{
              verticalAlign: "middle",
            }}
            size="large"
          >
            {utils.initialNames(auth_user.user.name)}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
