import { api } from './'
import * as Actions from "../redux/constants/Auth";

const simulationService = {}

const config = () => {
		return {
				headers: {'Authorization': `${localStorage.getItem(Actions.AUTH_TOKEN)}`}
		}
}

simulationService.simulation = async function (data) {
		let result = null;
		await api.post(`/api/simulations`, data, config())
				.then(res => {
						result = {data: res.data, error: false, type: res.status}
				}, config())
				.catch(error => {
						console.log('error', error)
						result = {data: null, error: true, type: error}
				})
		return result;
}

simulationService.lendingReasons = async function () {
	let result = null;
	const config = {
			headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
			}
	}
	await api.get(`/api/lending-reasons/all`)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

simulationService.getSimulation = async function (id) {
	let result = null;
	await api.get(`/api/simulations/user/${id}`, config())
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

simulationService.companySituations = async function () {
	let result = null;
	const config = {
			headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
			}
	}
	await api.get(`/api/company-situations/all`)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

export default simulationService
