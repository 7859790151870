// import axios from 'axios';
// import * as Actions from "../redux/constants/Auth";

const personalDataService = {}

personalDataService.model = {
    idStep: 0,
    nome: '',
    data: '',
    genero: '',
    estadoCivil: '',
    email: '',
    telefone: '',
    endereco: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
    nacionalidade: '',
    outraNacionalidade: ''
}

export default personalDataService
