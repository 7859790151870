import React from "react";
import { Route, Switch } from "react-router-dom";
import SolicitationViews from "views/solicitation-views";
import HeaderNav from "components/layout-components/HeaderNav";
import utils from "utils";
import { Grid, Layout } from "antd";

const { useBreakpoint } = Grid;
const { Content } = Layout;

export const SolicitationLayout = () => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Content>
        <div className="solicitation-container">
          <Switch>
            <Route path="" component={SolicitationViews} />
          </Switch>
        </div>
      </Content>
    </Layout>
  );
};

export default SolicitationLayout;
