import React from "react";
import { APP_NAME } from "configs/AppConfig";

// import * as Icons from '@ant-design/icons';

export default function Footer() {
  return (
    <footer className="footer justify-content-between">
      <span>
        &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> - Todos os
        direitos reservados.
      </span>

      <span>
        Desenvolvido por <strong>Going2</strong> em parceria com o{" "}
        <strong>Banco Pérola</strong>.
      </span>
      {/* <div><Icons.RocketOutlined /> Rocket IT</div> */}
      {/*<div>*/}
      {/*	<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Term & Conditions</a>*/}
      {/*	<span className="mx-2 text-muted"> | </span>*/}
      {/*	<a className="text-gray" href="/#" onClick={e => e.preventDefault()}>Privacy & Policy</a>*/}
      {/*</div>*/}
    </footer>
  );
}
