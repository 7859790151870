import axios from "axios";

import loginService from "./Login.Service";
import defaultService from "./Default.Service";
import uploadService from "./Upload.Service";
import perfilService from "./Perfil.Service";
import perfilAppService from "./PerfilApp.Service";
import cepService from "./CEP.Service";
import personalDataService from "./PersonalDataService";
import simulationService from "./Simulation.Service";
import usersService from "./Users.Service";
import profileService from "./Profile.Service";
import solicitationService from "./Solicitation.Service";
import projectsService from "./Projects.Service";
import analizeOperation from "./AnalizeOperation.Service";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
});

export {
  api,
  loginService,
  defaultService,
  uploadService,
  perfilService,
  usersService,
  perfilAppService,
  cepService,
  personalDataService,
  simulationService,
  profileService,
  solicitationService,
  projectsService,
  analizeOperation,
};
