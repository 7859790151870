import { api } from './'
import * as Actions from '../redux/constants/Auth'

const service = {}
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  }
}

const endpoint = 'projects'

service.model = {
  active: false,
  banner: '',
  description: '',
  title: '',
  url: ''
}

service.getProjects = async function (url) {
  let result = null
  await api
    .get(`/api/${endpoint}/url/${url}`)
    .then((res) => {
      result = { data: res.data, error: false, status: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      }
    })
  return result
}

service.getProjectsActive = async function (url) {
  let result = null
  await api
    .get(`/api/${endpoint}/all`)
    .then((res) => {
      result = { data: res.data, error: false, status: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      }
    })
  return result
}

export default service
