import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "constants/ThemeConstant";

export const APP_NAME = "Banco Pérola";
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const SIMULATION_PREFIX_PATH = "/simulacao";
export const CREDIT_PREFIX_PATH = "/credito";
export const SOLICITATION_PREFIX_PATH = "/solicitacao";
export const PROJECTS_PREFIX_PATH = "/projeto";
export const GUARANTOR_FORM_PREFIX_PATH = "/formulario-avalista";
export const GUARANTOR_FORM_PJ_PREFIX_PATH = "/formulario-avalista-pj";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "pt",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#ffffff",
  headerNavColor: "#ffffff",
  mobileNav: false,
};
