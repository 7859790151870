import * as Icon from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

const dashBoardNavTree = [
    {
        access: 0,
        key: 'home',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'home',
        icon: Icon.HomeOutlined,
        breadcrumb: false,
        submenu: []
    },
    {
        access: 1,
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        title: 'Operators',
        icon: Icon.TeamOutlined,
        breadcrumb: false,
        submenu: []
    },
    {
        access: 29,
        key: 'access',
        path: `${APP_PREFIX_PATH}/access`,
        title: 'access',
        icon: Icon.TagOutlined,
        breadcrumb: false,
        submenu: []
    },
    {
        access: 22,
        key: 'perfil',
        path: `${APP_PREFIX_PATH}/perfil`,
        title: 'perfil',
        icon: Icon.LockOutlined,
        breadcrumb: false,
        submenu: []
    },


]

const configAPPNavTree = [
    {
        key: 'manager-app',
        access: 32,
        title: 'Gerenciador Aplicativo',
        submenu: [
            {
                key: 'configapp',
                path: `${APP_PREFIX_PATH}/manager-app`,
                title: 'Configurações',
                icon: Icon.MobileOutlined,
                breadcrumb: false,
                submenu: [
                    {
                        key: 'home-app',
                        path: `${APP_PREFIX_PATH}/home-app`,
                        title: 'home app',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'menu-app',
                        path: `${APP_PREFIX_PATH}/manager-app/menu-app`,
                        title: 'Menu Aplicativo',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'modal-app',
                        path: `${APP_PREFIX_PATH}/manager-app/modal-app`,
                        title: 'Modal Aplicativo',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'perfil-app',
                        path: `${APP_PREFIX_PATH}/manager-app/perfil-app`,
                        title: 'Perfil',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'interests-app',
                        path: `${APP_PREFIX_PATH}/manager-app/interests-app`,
                        title: 'Interesses',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                ]
            },
            {
                key: 'portfolio-app',
                path: `${APP_PREFIX_PATH}/portfolio`,
                title: 'portfolio',
                icon: Icon.FileDoneOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'packages',
                path: `${APP_PREFIX_PATH}/packages`,
                title: 'packages',
                icon: Icon.ShoppingCartOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'plans',
                path: `${APP_PREFIX_PATH}/plans`,
                title: 'plans',
                icon: Icon.ShoppingOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'products',
                path: ``,
                title: 'Produtos',
                icon: Icon.MobileOutlined,
                breadcrumb: false,
                submenu: [
                    {
                        access: 6,
                        key: 'products',
                        path: `${APP_PREFIX_PATH}/manager-products/products`,
                        title: 'products',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        access: 3,
                        key: 'products-type',
                        path: `${APP_PREFIX_PATH}/manager-products/products-type`,
                        title: 'types',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        access: 5,
                        key: 'products-categories',
                        path: `${APP_PREFIX_PATH}/manager-products/products-categories`,
                        title: 'categories',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                ]
            },

            // {
            //   access: 8,
            //   key: 'products-itens',
            //   path: `${APP_PREFIX_PATH}/manager-products/itens`,
            //   title: 'itens',
            //   icon: Icon.ShoppingOutlined,
            //   breadcrumb: false,
            //   submenu: []
            // },
        ]
    }
]

const logsNavTree = [
    {
        key: 'manage-logs',
        access: 40,
        title: 'Logs',
        submenu: [
            {

                key: 'logs',
                path: `${APP_PREFIX_PATH}/logs`,
                title: 'Relatórios',
                icon: Icon.ProfileOutlined,
                breadcrumb: false,
                submenu: [
                    {
                        key: 'erros',
                        path: `${APP_PREFIX_PATH}/logs/log-errors`,
                        title: 'Erros',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'acoes',
                        path: `${APP_PREFIX_PATH}/logs/log-actions`,
                        title: 'Ações',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'acessos',
                        path: `${APP_PREFIX_PATH}/logs/log-access`,
                        title: 'Acessos',
                        icon: '',
                        breadcrumb: false,
                        submenu: []
                    },
                ]
            },
        ]
    }
]

const navigationConfig = [
    ...dashBoardNavTree,
    ...configAPPNavTree,
    ...logsNavTree
]

export default navigationConfig;
