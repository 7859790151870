import axios from 'axios';
import * as Actions from "../redux/constants/Auth";

const uploadService = {}

const config = () => {
	return {
			headers: {'Authorization': `${localStorage.getItem(Actions.AUTH_TOKEN)}`}
	}
}

uploadService.upload = async function (data) {
		let result = null;
		// const config = {
		// 		headers: {
		// 				'Content-Type': 'multipart/form-data',
		// 				'Authorization': `${localStorage.getItem(Actions.AUTH_TOKEN)}`
		// 		}
		// }
		await axios.post('/api/upload', data, config)
				.then(res => {
						result = {data: res.data, error: false, type: res.status}
				})
				.catch(error => {
						console.log('error', error)
						result = {data: null, error: true, type: error}
				})
		return result;
}

export default uploadService
