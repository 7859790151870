import axios from 'axios';
import * as Actions from "../redux/constants/Auth";

const cepService = {}
const config = () => {
    return {
            headers: {'Authorization': `${localStorage.getItem(Actions.AUTH_TOKEN)}`}
    }
}
let result = null;

cepService.getCEP = async function (strCEP) {
    await axios.get(`https://viacep.com.br/ws/${strCEP}//json/`)
        .then(res => {            
            result = {...res.data, error: false, type: res.status}
        })
        .catch(error => {
            console.log('error', error)
            result = {data: null, error: true, type: error}
        })
    return result;
}

export default cepService
