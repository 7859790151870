import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import SimulationLayout from "layouts/simulation-layout";
import SolicitationLayout from "layouts/solicitation-layout";
import ProjectsLayout from "layouts/projects-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  SIMULATION_PREFIX_PATH,
  SOLICITATION_PREFIX_PATH,
  PROJECTS_PREFIX_PATH,
  GUARANTOR_FORM_PREFIX_PATH,
  GUARANTOR_FORM_PJ_PREFIX_PATH,
} from "configs/AppConfig";
import { DataSolicitationProvider } from "../context/DataSolicitation";
import { GuarantorFormLayout } from "layouts/guarantor-form-layout";
import { GuarantorFormPjLayout } from "layouts/guarantor-form-pf-layout";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location } = props;
  const currentAppLocale = AppLocale[locale];
  return (
    <DataSolicitationProvider>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ConfigProvider locale={currentAppLocale.antd}>
          <Switch>
            <Route exact path="/">
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout />
            </Route>
            <Route path={SIMULATION_PREFIX_PATH}>
              <SimulationLayout />
            </Route>
            <Route path={SOLICITATION_PREFIX_PATH}>
              <SolicitationLayout />
            </Route>
            <Route path={PROJECTS_PREFIX_PATH}>
              <ProjectsLayout />
            </Route>
            <Route path={GUARANTOR_FORM_PREFIX_PATH} isAuthenticated={token}>
              <GuarantorFormLayout />
            </Route>
            <Route path={GUARANTOR_FORM_PJ_PREFIX_PATH} isAuthenticated={token}>
              <GuarantorFormPjLayout />
            </Route>
            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
              <AppLayout location={location} />
            </RouteInterceptor>
          </Switch>
        </ConfigProvider>
      </IntlProvider>
    </DataSolicitationProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
