import React from "react";
import { Button } from "antd";

import Content from "./style";
import { useHistory } from "react-router-dom";

export const ButtonLogin = () => {
  let history = useHistory();

  const pageLogin = () => {
    history.push("/auth/login");
  };

  return (
    <Content>
      <Button
        type="primary"
        className="buttonLogin"
        onClick={() => pageLogin()}
      >
        Login
      </Button>
    </Content>
  );
};

export default ButtonLogin;
