import { api } from '.'
import * as Actions from '../redux/constants/Auth'

const analizeOperation = {}
const endpoint = '/api/creditOperation'
const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  }
}

analizeOperation.getOperationByUserId = async function (id) {
  let result = null
  await api
    .get(`${endpoint}/user/${id}`, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status ? error.response.status : null,
      }
    })
  return result
}

analizeOperation.getCasualtiesSeletivas = async function (id) {
  let result = null;
  let url = "";

  url = `${endpoint}/${id}/installments?filter[status]=pendente&filter[status]=quitadaParcialmente`;

  await api
    .get(url, config())
    .then((res) => {
      result = { ...res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = { data: null, error: true, type: error };
    });
  return result;
};

analizeOperation.sendCompletBill = async function (credit_operationId, data) {
  let result = null;
  let url = "";
  url = `${endpoint}/${credit_operationId}/boleto/carne`;

  await api
    .post(url, data, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};

analizeOperation.getIssueBill = async function (credit_operationId, installlmentId) {
  let result = null;
  let url = "";

  url = `${endpoint}/${credit_operationId}/boleto/${installlmentId}`;

  await api
    .get(url, config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status };
    })
    .catch((error) => {
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      };
    });
  return result;
};
export default analizeOperation
