import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { SOLICITATION_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route
          path={`${SOLICITATION_PREFIX_PATH}/:url`}
          component={lazy(() => import(`./authentication/solicitation`))}
        />

        {/* <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-2`))} /> */}
        <Redirect
          from={`${SOLICITATION_PREFIX_PATH}`}
          to={`${SOLICITATION_PREFIX_PATH}/perola`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
