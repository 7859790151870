import React, { createContext, useState } from 'react';

const DataSimulationContext = createContext();

function DataSimulationProvider({ children }) {
  const [respDataSimulation, setRespDataSimulation] = useState([]);
  const [dataSimulation, setDataSimulation] = useState({
    user_id: null,
    lendingValue: null,
    lendingPlotsToPay: null,
    companySituation: null,
    lendingReason: null,
    percentage: null,
    lendingRate: null,
    plotTotal: null,
    arrayCompanySituation: null,
    arrayLendingReason: null,
  });

  const setSimulationData = (label, value) => {
    setDataSimulation({ ...dataSimulation, [label]: value });
  };

  const setRespSimulationData = (data) => {
    setRespDataSimulation(data);
  };

  return (
    <DataSimulationContext.Provider
      value={{
        dataSimulation,
        respDataSimulation,
        setSimulationData,
        setRespSimulationData,
      }}
    >
      {children}
    </DataSimulationContext.Provider>
  );
}

export { DataSimulationProvider, DataSimulationContext };
