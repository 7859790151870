import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, GUARANTOR_FORM_PJ_PREFIX_PATH } from 'configs/AppConfig';

const GuarantorForm = lazy(() => import(`./Form`));

export const GuarantorFormPjViews = () => {
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route
          path={`${GUARANTOR_FORM_PJ_PREFIX_PATH}`}
          component={GuarantorForm}
        />
          
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};
