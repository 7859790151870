import { notification } from "antd";
const locale = "pt-br";

class Utils {

  static makeHash = () => {
    let _hash = '';
    const possible = "abcdfghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ00112233445566778899";
    for (let i = 0; i < 25; i++) {
      _hash += possible.charAt(Math.floor(Math.random() * possible.length));
    };
    return _hash;
  }

  /**
   * Fast message to show
   * @param {String} value
   */
  static currencyFormatter = (selectedCurrOpt) => (value) => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "BRAZIL::BRL".split("::")[1],
    }).format(value);
  };

  /**
   * Fast message to show
   * @param {String} val
   */
  static currencyParser = (val) => {
    try {
      if (typeof val === "string" && !val.length) {
        val = "0.0";
      }

      var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
      var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
      var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");

      reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");

      reversedVal = reversedVal.replace(/[^0-9.]/g, "");

      const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Fast message to show
   * @param {Double} value
   */
  static convertCurrency(value = "0") {
    return value.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  /**
   * Fast message to show
   * @param {String} value
   */
  static initialNames(value) {
    let name = value;
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();

    return initials;
  }

  /**
   * Fast message to show
   * @param {String} email
   */
  static validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(email);
  }

  /**
   * Fast message to show
   * @param {String} type
   * @param {String} title
   * @param {String} message
   */
  static feedback(type, title, message) {
    notification[type]({
      message: title,
      description: message,
    });
  }

  static toMoneyBr(value = 0) {
    return Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value);
  }

  /**
   * Validate perfil to show components and enable functionalities in the screen
   * @param {Number} roles
   * @param {Array} perfil
   * @param {String} type
   * @return {Boolean} show
   */
  static validationPerfil(roles, perfil, type, debug = false, func = "") {
    // if (debug) console.log("------------------------------ FUNCAO", func);
    // if (debug)
    //   console.log(
    //     "validationPerfil >>> ",
    //     roles,
    //     `perfil: ${perfil}`,
    //     `type: ${type}`,
    //     `test: ${debug}`
    //   );
    let result = true;
    // if (roles === 1) {
    //   result = true;
    // } else {
    //   if (debug)
    //     console.log(
    //       "validationPerfil ??? ",
    //       perfil.find((x) => x === roles)
    //     );
    //   result = perfil.find((x) => x === roles) ? true : false;
    // }
    // if (debug) console.log("result === ", result);
    // if (debug) console.log("------------------------------");
    // if (debug) console.log("------------------------------");
    return result;
  }

static maskCPF(value){
  const res = value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace( /(\d{3})(\d{1,2})$/ , "$1-$2")
  .replace(/(-\d{2})\d+?$/, '$1');
  return res;
}

static validateCPF(strCPF) {
  if (!strCPF || typeof strCPF !== 'string') return false;

  const cpf = strCPF.replace(/\D/g, '');

  if (cpf.length !== 11) return false;
  if (/^(\d)\1{10}$/.test(cpf)) return false; 

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;

  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;

  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true;
}


static validateCNPJ(cnpj) {
  if (!cnpj || typeof cnpj !== 'string') return false;

  const cnpjNumbers = cnpj.replace(/\D/g, '');

  if (cnpjNumbers.length !== 14) return false;
  if (/^(\d)\1{13}$/.test(cnpjNumbers)) return false; 

  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let soma = 0;
  let resto;

  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpjNumbers.charAt(i)) * b[i + 1];
  }

  resto = soma % 11;
  if (resto < 2) {
    resto = 0;
  } else {
    resto = 11 - resto;
  }

  if (parseInt(cnpjNumbers.charAt(12)) !== resto) return false;

  soma = 0;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpjNumbers.charAt(i)) * b[i];
  }

  resto = soma % 11;
  if (resto < 2) {
    resto = 0;
  } else {
    resto = 11 - resto;
  }

  if (parseInt(cnpjNumbers.charAt(13)) !== resto) return false;

  return true;
}


static validateFields = (
    value,
    message,
    lengthList,
  ) => {
    let error = false;
    if (value.length > 0) {
      if (lengthList.length && !lengthList.some(el => el < value.length))
        error = message;
    } else {
      error = 'Preenchimento obrigatório!';
    }
    console.log('err', error);
    return error;
};

static maskCNPJ(value){
    const res = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    //.replace( /(\d{3})(\d{1,2})$/ , "$1/$2")
    .replace(/(-\d{2})\d+?$/, '$1');
    return res;
  }

  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);

    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }

    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }

    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }

    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }

    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  static formattedDate = (d) => {
    const date = new Date(d);
    date.setDate(date.getDate() + 1);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("pt-BR", options);
  };
}

export default Utils;
