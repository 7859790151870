import * as Actions from "../constants/Socket.constants";

const initState = {
  socketio: null,
  alerts: [],
};

const socket = (state = initState, action) => {
  switch (action.type) {
    case Actions.SOCKET_IO: {
      return {
        ...state,
        socketio: action.payload,
      };
    }
    case Actions.SOCKET_NEW_ALERT: {
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    }
    case Actions.SOCKET_CLEAR_ALERTS: {
      return {
        ...state,
        alerts: [],
      };
    }
    default:
      return state;
  }
};

export default socket;
