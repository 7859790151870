import React, { createContext, useState } from 'react';
import { EMPTY_SOLICITATION_DATA } from '../constants/SolicitationConstant';

const DataSolicitationContext = createContext();

function DataSolicitationProvider({children}){
    const [infoSolicitation, setInfoSolicitation] = useState({});
    const [dataSolicitation, setDataSolicitation] = useState(EMPTY_SOLICITATION_DATA);
        
    const[questionPJ, setQuestionPJ] = useState({
        data:[
            {
                title:'Dados financeiros',
                idStep:1,
                type:'info',
                foreign:null,
                questions:[
                    {   
                        question:'Valor do crédito',
                        resp:'lendingValue'
                    },
                    {   
                        question:'Total de parcelas',
                        resp:'lendingPlotsToPay'
                    },
                    {   
                        question:'Tipo de crédito',
                        resp:'typeCredit'
                    },
                    {   
                        question:'Ramo de atividade',
                        resp:'branchOfActivity',
                        respOld:''
                    },
                ]
            },
            {
                title:'Dados pessoais',
                idStep:3,
                type:'info',
                foreign:null,
                questions:[
                    {   
                        question:'Nome completo',
                        resp:'legalName'
                    },
                    {   
                        question:'CPF',
                        resp:'cpf'
                    },
                    {   
                        question:'Data de nascimento',
                        resp:'birthday'
                    },
                    {   
                        question:'Gênero',
                        resp:'genre'
                    },
                    {   
                        question:'Estado civil',
                        resp:'relations'
                    },
                    {   
                        question:'E-mail para contato',
                        resp:'email'
                    },
                    {   
                        question:'Telefone para contato',
                        resp:'phone'
                    },
                    {   
                        question:'CEP',
                        resp:'cep'
                    },
                    {   
                        question:'Endereço residencial',
                        resp:'address'
                    },
                    {   
                        question:'Número',
                        resp:'addressNumber'
                    },
                    {   
                        question:'Complemento',
                        resp:'addressComplement'
                    },
                    {   
                        question:'Bairro',
                        resp:'neighborhood'
                    },
                    {   
                        question:'Cidade',
                        resp:'city'
                    },
                    {   
                        question:'Estado (UF)',
                        resp:'state'
                    },
                    {   
                        question:'Nacionalidade',
                        resp:'nationality'
                    }
                ]
            },
            {
                title:'Dados da empresa',
                idStep:4,
                type:'info',
                foreign:null,
                questions:[
                    {   
                        question:'CNPJ',
                        resp:'cnpj'
                    },
                    {   
                        question:'Nome fantasia da sua empresa',
                        resp:'companyName'
                    },
                    {   
                        question:'CEP',
                        resp:'companyCep'
                    },
                    {   
                        question:'Endereço comercial',
                        resp:'companyAddress'
                    },
                    {   
                        question:'Número',
                        resp:'companyAddressNumber'
                    },
                    {   
                        question:'Complemento',
                        resp:'companyAddressComplement'
                    },
                    {   
                        question:'Bairro',
                        resp:'companyAddressNeighborhood'
                    },
                    {   
                        question:'Cidade',
                        resp:'companyCity'
                    },
                    {   
                        question:'Estado (UF)',
                        resp:'companyState'
                    },
                    {   
                        question:'Tipo da empresa',
                        resp:'companyType'
                    },
                    {   
                        question:'Site',
                        resp:'site'
                    },
                    {   
                        question:'Instagram',
                        resp:'instagram'
                    },
                    {   
                        question:'Facebook',
                        resp:'facebook'
                    }
                ]
            },
            {
                title:'Dados bancários',
                idStep:5,
                type:'info',
                foreign:null,
                questions:[
                    {   
                        question:'Perfil da conta',
                        resp:'profileAccountBank'
                    },
                    {   
                        question:'CPF ou CNPJ da conta',
                        resp:'cpfCnpj'
                    },
                    {   
                        question:'Nome do favorecido',
                        resp:'nameCreditCard'
                    },
                    {   
                        question:'Nome do seu banco',
                        resp:'bankName'
                    },
                    {   
                        question:'Número da sua agência sem o dígito',
                        resp:'bankAgency'
                    },
                    {   
                        question:'Número da conta sem dígito',
                        resp:'bankNumber'
                    },
                    {   
                        question:'Dígito da conta',
                        resp:'bankDigit'
                    },
                    {   
                        question:'Tipo de conta',
                        resp:'typeAccount'
                    },
                    {   
                        question:'Chave PIX',
                        resp:'keyPix'
                    }
                ]
            },
            {
                title:'Documentos pessoais',
                idStep:6,
                type:'doc',
                foreign:'brasileiro',
                questions:[
                    {   
                        question:'RG ou CNH (frente)',
                        resp:'docFrente',
                        id:6
                    },
                    {   
                        question:'RG ou CNH (verso)',
                        resp:'docVerso',
                        id:7
                    },
                    {   
                        question:'CPF',
                        resp:'fotoRgCnh',
                        id:5
                    },
                    {   
                        question:'Foto selfie (sua) segurando um documento com foto',
                        resp:'docSelfie',
                        id:12
                    },
                    {   
                        question:'Comprovante de residência',
                        resp:'comprResid',
                        id:8
                    },
                    {   
                        question:'Foto do cabeçalho do extrato bancário',
                        resp:'extratoBancario',
                        id:9
                    }
                ]
            },
            {
                title:'Documentos pessoais',
                idStep:6,
                type:'doc',
                foreign:'estrangeiro',
                questions:[
                    {   
                        question:'Tipo de documento de identificação',
                        resp:'typeDocument',
                        id:null
                    },
                    {   
                        question:'Número do documento',
                        resp:'numDocumentForeign',
                        id:null
                    },
                    {   
                        question:'RNM ou Protocolo',
                        resp:'docRNM',
                        id:15
                    },
                    {   
                        question:'Comprovante de residência',
                        resp:'comprResid',
                        id:8
                    },
                    {   
                        question:'Extrato bancário',
                        resp:'extratoBancario',
                        id:9
                    },
                    {   
                        question:'Foto selfie',
                        resp:'docSelfie',
                        id:12
                    },
                    {   
                        question:'Foto do seu negócio ou atividade produtiva',
                        resp:'docRefugeProtocol',
                        id:14
                    }
                ]
            },
            {
                title:'Documentos da empresa',
                idStep:7,
                type:'doc',
                foreign:null,
                questions:[
                    {   
                        question:'Contrato social ou certificado MEI',
                        resp:'certiMEI',
                        id:1
                    },
                    {   
                        question:'Extrato bancário dos últimos 3 meses',
                        resp:'extraBancarioEmpresa',
                        id:10
                    },
                    {   
                        question:'Foto: fachada do empreendimento',
                        resp:'fotoFachada',
                        id:3
                    },
                    {   
                        question:'Foto: interior do empreendimento',
                        resp:'fotoInterior',
                        id:4
                    },
                    {   
                        question:'Comprovante de endereço da sua empresa',
                        resp:'compEndereco',
                        id:11
                    }
                ]
            }
        ]
    });

    const [questionPF, setQuestionPF] = useState({
        data:[
            {
                title:'Dados financeiros',
                idStep:1,
                type:'info',
                foreign:null,
                questions:[
                    {   
                        question:'Valor do crédito',
                        resp:'lendingValue'
                    },
                    {   
                        question:'Total de parcelas',
                        resp:'lendingPlotsToPay'
                    },
                    {   
                        question:'Tipo de crédito',
                        resp:'typeCredit'
                    },
                    {   
                        question:'Ramo de atividade',
                        resp:'branchOfActivity',
                        respOld:''
                    },
                ]
            },
            {
                title:'Dados pessoais',
                idStep:2,
                type:'info',
                foreign:null,
                questions:[
                    {   
                        question:'Nome completo',
                        resp:'legalName'
                    },
                    {   
                        question:'CPF',
                        resp:'cpf'
                    },
                    {   
                        question:'Data de nascimento',
                        resp:'birthday'
                    },
                    {   
                        question:'Gênero',
                        resp:'genre'
                    },
                    {   
                        question:'Estado civil',
                        resp:'relations'
                    },
                    {   
                        question:'E-mail para contato',
                        resp:'email'
                    },
                    {   
                        question:'Telefone para contato',
                        resp:'phone'
                    },
                    {   
                        question:'CEP',
                        resp:'cep'
                    },
                    {   
                        question:'Endereço residencial',
                        resp:'address'
                    },
                    {   
                        question:'Número',
                        resp:'addressNumber'
                    },
                    {   
                        question:'Complemento',
                        resp:'addressComplement'
                    },
                    {   
                        question:'Bairro',
                        resp:'neighborhood'
                    },
                    {   
                        question:'Cidade',
                        resp:'city'
                    },
                    {   
                        question:'Estado (UF)',
                        resp:'state'
                    },
                    {   
                        question:'Nacionalidade',
                        resp:'nationality'
                    }
                ]
            },
            {
                title:'Dados bancários',
                idStep:3,
                type:'info',
                foreign:null,
                questions:[
                    {   
                        question:'Perfil da conta',
                        resp:'profileAccountBank'
                    },
                    {   
                        question:'CPF ou CNPJ da conta',
                        resp:'cpfCnpj'
                    },
                    {   
                        question:'Nome do favorecido',
                        resp:'nameCreditCard'
                    },
                    {   
                        question:'Nome do seu banco',
                        resp:'bankName'
                    },
                    {   
                        question:'Número da sua agência sem o dígito',
                        resp:'bankAgency'
                    },
                    {   
                        question:'Número da conta sem dígito',
                        resp:'bankNumber'
                    },
                    {   
                        question:'Dígito da conta',
                        resp:'bankDigit'
                    },
                    {   
                        question:'Tipo de conta',
                        resp:'typeAccount'
                    },
                    {   
                        question:'Chave PIX',
                        resp:'keyPix'
                    }
                ]
            },
            {
                title:'Documentos pessoais',
                idStep:4,
                type:'doc',
                foreign:'brasileiro',
                questions:[
                    {   
                        question:'RG ou CNH (frente) 1',
                        resp:'docFrente',
                        id:6
                    },
                    {   
                        question:'RG ou CNH (verso)',
                        resp:'docVerso',
                        id:7
                    },
                    {   
                        question:'CPF',
                        resp:'fotoRgCnh',
                        id:5
                    },
                    {   
                        question:'Foto selfie (sua) segurando um documento com foto',
                        resp:'docSelfie',
                        id:12
                    },
                    {   
                        question:'Comprovante de residência',
                        resp:'comprResid',
                        id:8
                    },
                    {   
                        question:'Foto do cabeçalho do extrato bancário',
                        resp:'extratoBancario',
                        id:9
                    }
                ]
            },
            {
                title:'Documentos pessoais',
                idStep:4,
                type:'doc',
                foreign:'estrangeiro',
                questions:[
                    {   
                        question:'Tipo de documento de identificação',
                        resp:'typeDocument',
                        id:null
                    },
                    {   
                        question:'Número do documento',
                        resp:'numDocumentForeign',
                        id:null
                    },
                    {   
                        question:'RNM ou Protocolo',
                        resp:'docRNM',
                        id:15
                    },
                    {   
                        question:'Comprovante de residência',
                        resp:'comprResid',
                        id:8
                    },
                    {   
                        question:'Extrato bancário',
                        resp:'extratoBancario',
                        id:9
                    },
                    {   
                        question:'Foto selfie',
                        resp:'docSelfie',
                        id:12
                    },
                    {   
                        question:'Foto do seu negócio ou atividade produtiva',
                        resp:'docRefugeProtocol',
                        id:14
                    }
                ]
            }
        ]
    });
    
    const setSolicitationData = (data) => {
        setDataSolicitation({...dataSolicitation, ...data});
    }

    const setDataInfo = (data) =>{
        setInfoSolicitation({...infoSolicitation, ...data});
    }

    const resetSolicitationData = () => {
        setDataSolicitation(EMPTY_SOLICITATION_DATA);
        setInfoSolicitation({})
    }
    
    return (
      <DataSolicitationContext.Provider
        value={{
          dataSolicitation,
          setSolicitationData,
          setDataInfo,
          infoSolicitation,
          questionPF,
          questionPJ,
          resetSolicitationData,
        }}
      >
        {children}
      </DataSolicitationContext.Provider>
    );
}


export {DataSolicitationProvider, DataSolicitationContext}