import React, {useState} from "react";
import {connect, useSelector} from "react-redux";
import {Layout} from "antd";
import Logo from './Logo';
import NavSearch from './NavSearch';
import NavProfile from './NavProfile';
import ButtonLogin from './ButtonLogin';
import {onMobileNavToggle, toggleCollapsedNav} from 'redux/actions/Theme';
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'constants/ThemeConstant';
import utils from 'utils'

const {Header} = Layout;

export const HeaderNav = props => {
  const {navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile} = props;
  const [searchActive, setSearchActive] = useState(false)
  const auth_user = useSelector(({ auth }) => auth);

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = utils.getColorContrast(headerNavColor)
  const getNavWidth = () => {
    if(isNavTop || isMobile) {
      return '0px'
    }
    if(navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }
  return (
    <Header className={`app-header ${mode}`} style={{backgroundColor: headerNavColor}}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode}/>
        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
          <div className="nav-left">
            {/* <Menu mode="horizontal">
              {
                isNavTop && !isMobile ?
                null
                :
                <Menu.Item key="0" onClick={() => {onToggle()}}>
                  {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon text-gray" /> : <MenuFoldOutlined className="nav-icon text-gray" />}
                </Menu.Item>
              }
            </Menu> */}
          </div>
          <div className="nav-right">
            {auth_user?.user?.name?<NavProfile />: <ButtonLogin/>}
          </div>
          <NavSearch active={searchActive} close={onSearchClose}/>
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } =  theme;
  return { navCollapsed, navType, headerNavColor, mobileNav }
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav);
