import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { DataSolicitationProvider } from '../../context/DataSolicitation';
import { DataSimulationProvider } from '../../context/DataSimulation';

export const AppViews = () => {
  return (
    <DataSolicitationProvider>
      <DataSimulationProvider>
        <Suspense fallback={<Loading cover='content' />}>
          <Switch>
            <Route
              path={`${APP_PREFIX_PATH}/home`}
              component={lazy(() => import(`./home`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/profile`}
              component={lazy(() => import(`./profile`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/solicitation/:id`}
              component={lazy(() => import(`./solicitation`))}
            />
            <Redirect
              from={`${APP_PREFIX_PATH}`}
              to={`${APP_PREFIX_PATH}/home`}
            />
          </Switch>
        </Suspense>
      </DataSimulationProvider>
    </DataSolicitationProvider>
  );
};

export default React.memo(AppViews);
