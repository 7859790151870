import React, { useEffect, useState } from 'react';
import { Grid, Layout } from 'antd';
import { useHistory } from 'react-router-dom';

import TopNav from 'components/layout-components/TopNav';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
//import {SocketIO} from 'components';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import navigationConfig from 'configs/NavigationConfig';
import { NAV_TYPE_TOP } from 'constants/ThemeConstant';
import utils from 'utils';

import * as Actions from 'redux/actions';
import * as Services from 'services';
import { connect, useDispatch } from 'react-redux';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location }) => {
  //const auth_user = useSelector(({auth}) => auth);

  let history = useHistory();

  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavTop = navType === NAV_TYPE_TOP;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  // REFRESH TOKEN
  useEffect(() => {
    setLoading(false);
    validateLogged();
  }, []);

  const validateLogged = async () => {
    dispatch(Actions.openLoading());
    const result = await Services.loginService.validateLogged();
    if (result.status === 401) {
      history.push('/auth/logout');
    }
    dispatch(Actions.closeLoading());
  };

  return (
    <Layout>
      {/*<SocketIO/>*/}
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className='app-container'>
        {/* {isNavSide && !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} />
        ) : null} */}
        <Layout
          className='app-layout'
          //   style={{ paddingLeft: getLayoutGutter() }}
        >
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            {!loading && (
              <>
                <PageHeader
                  display={currentRouteInfo?.breadcrumb}
                  title={currentRouteInfo?.title}
                />
                <Content>
                  <AppViews />
                </Content>
              </>
            )}
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
