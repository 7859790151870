export const SIGNIN = '[AUTH] SIGNIN';
export const UPDATE_USER = '[AUTH] UPDATE USER';
export const AUTHENTICATED = '[AUTH] AUTHENTICATED';
export const SIGNOUT = '[AUTH] SIGNOUT';
export const SIGNOUT_SUCCESS = '[AUTH] SIGNOUT_SUCCESS'
export const SIGNUP = '[AUTH] SIGNUP';
export const SIGNUP_SUCCESS = '[AUTH] SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = '[AUTH] SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = '[AUTH] HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = '[AUTH] SHOW_LOADING'
export const HIDE_LOADING = '[AUTH] HIDE_LOADING'
export const AUTH_TOKEN = '[AUTH] auth_token'
export const AUTH_USER = '[AUTH] auth_user'
export const SIGNIN_WITH_GOOGLE = '[AUTH] SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = '[AUTH] SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = '[AUTH] SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = '[AUTH] SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
